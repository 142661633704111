@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h2 {
    @apply font-semibold text-xl md:text-2xl mb-3;
  }

  h3 {
    @apply font-semibold;
  }
}

@layer components {
  button {
    @apply border-0 bg-transparent hover:bg-gray-300 hover:text-white transition;
  }

  .container {
    @apply text-xs md:text-sm mb-8 mt-4 md:mt-7;
  }

  .container p {
    @apply mb-2;
  }

  .container ul {
    @apply mt-4 mb-8 list-disc ml-6;
  }
}
